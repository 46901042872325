import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index';

const routes = [
  { path: '/login', name: 'login', component: () => import('../views/LoginView.vue') },
  { path: '/password_reset', name: 'password_reset', component: () => import('../views/PasswordResetView.vue') },
  { path: '/menu', name: 'menu', component: () => import('../views/MenuView.vue'),
    children: [
      { path: 'home', name: 'home', component: () => import('../views/HomeView.vue') },
      { path: 'user_detail/:id', name: 'user_detail', component: () => import('../views/user/UserDetailView.vue') },
      { path: 'user_list', name: 'user_list', component: () => import('../views/user/UserListView.vue') },
      { path: 'user_view', name: 'user_view', component: () => import('../views/user/UserView.vue') },
      { path: 'user_view/:dep_id', name: 'user_view_with_dep_id', component: () => import('../views/user/UserView.vue') },
      { path: 'user_add', name: 'user_add', component: () => import('../views/user/UserAddView.vue') },
      { path: 'user_edit/:id', name: 'user_edit', component: () => import('../views/user/UserEditView.vue') },
      { path: 'setting', name: 'setting', component: () => import('../views/setting/SettingView.vue') },
      { path: 'company_detail', name: 'company_detail', component: () => import('../views/setting/CompanyDetailView.vue') },
      { path: 'department_list', name: 'department_list', component: () => import('../views/department/DepartmentListView.vue') },
      { path: 'department_add', name: 'department_add', component: () => import('../views/department/DepartmentAddView.vue') },
      { path: 'department_detail/:dep_id', name: 'department_detail', component: () => import('../views/department/DepartmentDetailView.vue') },
      { path: 'department_edit/:dep_id', name: 'department_edit', component: () => import('../views/department/DepartmentEditView.vue') },
      { path: 'system_config', name: 'system_config', component: () => import('../views/setting/SystemConfigView.vue') },
      { path: 'account_list', name: 'account_list', component: () => import('../views/setting/AccountListView.vue') },
      { path: 'account_detail/:account_cd', name: 'account_detail', component: () => import('../views/setting/AccountDetailView.vue') },
      { path: 'account_sub_detail/:account_cd/:account_sub_cd', name: 'account_sub_detail', component: () => import('../views/setting/AccountSubDetailView.vue') },
      { path: 'unit_list', name: 'unit_list', component: () => import('../views/setting/UnitListView.vue') },
      { path: 'unit_detail/:unit_cd', name: 'unit_detail', component: () => import('../views/setting/UnitDetailView.vue') },
      { path: 'bank_list', name: 'bank_list', component: () => import('../views/setting/BankListView.vue') },
      { path: 'bank_detail/:id', name: 'bank_detail', component: () => import('../views/setting/BankDetailView.vue') },
      { path: 'phase_list', name: 'phase_list', component: () => import('../views/setting/PhaseListView.vue') },
      { path: 'holiday_list', name: 'holiday_list', component: () => import('../views/setting/HolidayListView.vue') },
      { path: 'extension_list', name: 'extension_list', component: () => import('../views/setting/ExtensionListView.vue') },
      { path: 'import', name: 'import', component: () => import('../views/setting/ImportView.vue') },
      { path: 'password_change', name: 'password_change', component: () => import('../views/user/PasswordChangeView.vue') },
      { path: 'schedule_list', name: 'schedule_list', component: () => import('../views/schedule/ScheduleListView.vue') },
      { path: 'notification_list', name: 'notification_list', component: () => import('../views/client/ClientListView.vue') },
      { path: 'client_list', name: 'client_list', component: () => import('../views/client/ClientListView.vue') },
      { path: 'client_detail/:client_cd', name: 'client_detail', component: () => import('../views/client/ClientDetailView.vue') },
      { path: 'goods_list', name: 'goods_list', component: () => import('../views/goods/GoodsListView.vue') },
      { path: 'goods_detail/:goods_cd', name: 'goods_detail', component: () => import('../views/goods/GoodsDetailView.vue') },
      { path: 'department_view', name: 'department_view', component: () => import('../views/department/DepartmentView.vue') },
      { path: 'activities_list', name: 'activities_list', component: () => import('../views/client/ClientListView.vue') },
      { path: 'bus_list', name: 'bus_list', component: () => import('../views/bus/BusListView.vue') },
      { path: 'bus_detail/:bus_no', name: 'bus_detail', component: () => import('../views/bus/BusDetailView.vue') },
      { path: 'estimate_list', name: 'estimate_list', component: () => import('../views/estimate/EstimateListView.vue') },
      { path: 'estimate_detail/:estimate_no', name: 'estimate_detail', component: () => import('../views/estimate/EstimateDetailView.vue') },
      { path: 'receive_list', name: 'receive_list', component: () => import('../views/receive/ReceiveListView.vue') },
      { path: 'receive_detail/:receive_no', name: 'receive_detail', component: () => import('../views/receive/ReceiveDetailView.vue') },
      { path: 'sales_list', name: 'sales_list', component: () => import('../views/sales/SalesListView.vue') },
      { path: 'sales_detail/:sales_no', name: 'sales_detail', component: () => import('../views/sales/SalesDetailView.vue') },
      { path: 'activities_list', name: 'activities_list', component: () => import('../views/activities/ActivitiesListView.vue') },
      { path: 'order_list', name: 'order_list', component: () => import('../views/order/OrderListView.vue') },
      { path: 'order_detail/:order_no', name: 'order_detail', component: () => import('../views/order/OrderDetailView.vue') },
      { path: 'buying_list', name: 'buying_list', component: () => import('../views/buying/BuyingListView.vue') },
      { path: 'buying_detail/:buying_no', name: 'buying_detail', component: () => import('../views/buying/BuyingDetailView.vue') },
      { path: 'delivery_slip', name: 'delivery_slip', component: () => import('../views/delivery_slip/DeliverySlipListView.vue') },
      { path: 'invoice_report', name: 'invoice_report', component: () => import('../views/invoice/InvoiceReportView.vue') },
      { path: 'invoice_list', name: 'invoice_list', component: () => import('../views/invoice/InvoiceListView.vue') },
      { path: 'invoice_detail/:invoice_no', name: 'invoice_detail', component: () => import('../views/invoice/InvoiceDetailView.vue') },
      { path: 'invoice_sum', name: 'invoice_sum', component: () => import('../views/invoice/InvoiceSumView.vue') },
      { path: 'invoice_zan', name: 'invoice_zan', component: () => import('../views/invoice/InvoiceZanView.vue') },
      { path: 'receipt_list', name: 'receipt_list', component: () => import('../views/receipt/ReceiptListView.vue') },
      { path: 'receipt_detail/:receipt_no', name: 'receipt_detail', component: () => import('../views/receipt/ReceiptDetailView.vue') },
      { path: 'receipt_apply', name: 'receipt_apply', component: () => import('../views/receipt/ReceiptApplyView.vue') },
      { path: 'urikake_list', name: 'urikake_list', component: () => import('../views/urikake/UrikakeListView.vue') },
      { path: 'urikake_year', name: 'urikake_year', component: () => import('../views/urikake/UrikakeYearView.vue') },
      { path: 'adjust_list', name: 'adjust_list', component: () => import('../views/adjust/AdjustListView.vue') },
      { path: 'adjust_detail/:adjust_no', name: 'adjust_detail', component: () => import('../views/adjust/AdjustDetailView.vue') },
      { path: 'adjust_sum', name: 'adjust_sum', component: () => import('../views/adjust/AdjustSumView.vue') },
      { path: 'payment_list', name: 'payment_list', component: () => import('../views/payment/PaymentListView.vue') },
      { path: 'payment_detail/:payment_no', name: 'payment_detail', component: () => import('../views/payment/PaymentDetailView.vue') },
      { path: 'payment_apply', name: 'payment_apply', component: () => import('../views/payment/PaymentApplyView.vue') },
      { path: 'kaikake_list', name: 'kaikake_list', component: () => import('../views/kaikake/KaikakeListView.vue') },
      { path: 'kaikake_year', name: 'kaikake_year', component: () => import('../views/kaikake/KaikakeYearView.vue') },
      { path: 'permission_department_list', name: 'permission_department_list', component: () => import('../views/setting/PermissionDepartmentListView.vue') },
      { path: 'permission_user_list', name: 'permission_user_list', component: () => import('../views/setting/PermissionUserListView.vue') },
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name != 'login' && to.name != 'password_reset') {
    if (store === undefined || !store.getters.isAuthenticated) {
      return { name: 'login' };
    }
  }
  router['referrer'] = from
  next()
});

export default router
