import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { createI18n } from 'vue-i18n'

if (location.hostname == 'demo.mssusers.com') {
	axios.defaults.baseURL = 'https://demo.mssusers.com/api'
	axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://demo.mssusers.com'
} else {
	axios.defaults.baseURL = 'http://127.0.0.1:8000/api'
	axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://127.0.0.1:8000'
}
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.withCredentials = true
axios.interceptors.request.use(function (config) {
	if (store.getters.token) {
		axios.defaults.headers.common['Authorization'] = 'Token ' + store.getters.token
		config.headers.Authorization = 'Token ' + store.getters.token
	} else {
		axios.defaults.headers.common['Authorization'] = ''
		config.headers.Authorization = ''
	}
	return config
}, function (error) {
	return Promise.reject(error)
})

const data = require('./message.json');

const i18n = createI18n({
	locale: 'ja',
	fallbackLocale: "en",
	messages: data
})

createApp(App).use(store).use(router, axios).use(i18n).mount('#app')
