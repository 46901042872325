import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    isAuthenticated: false
  },
  getters: {
    token(state) {
      return state.token
    },
    isAuthenticated(state) {
      return state.isAuthenticated
    }
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('sales_token')) {
        state.token = localStorage.getItem('sales_token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
      localStorage.setItem('sales_token', token)
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    }
  },
  actions: {
  },
  modules: {
  }
})
